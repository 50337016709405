<template>

  <the-modal :isBig="true" @closeModal="closeModal">
    <template v-slot:body="">
      <h5 class="mb-3">Create your own watch list to organize your favourites:</h5>
      <div class="form-group">
        <label> Type of watch list</label>
        <select class="form-control collection-type-select" v-model="form.type">
         <option v-for="option in options" :value="option.value">{{option.label}}</option>
       </select>
       <span class="validation-error" v-show="showListTypeError">Select the type of watch list.</span>
     </div>
     <div class="form-group form-title">
      <label>Name of your watch list</label>
      <input type="text" class="form-control reiseplan-titel" placeholder="Title" @blur="validateListTitle" v-model="form.name">
      <span class="validation-error" v-show="showListNameError">Enter a name for the list.</span>
    </div>
    <div class="form-group description-text">
      <label>Description (optional)</label>
      <textarea cols="30" rows="6" class="form-control" placeholder="Description" v-model="form.description"></textarea>
    </div>
    <button class="btn btn-hover mt-3 btn-block" @click="dispatchCreateCollection">Create</button>
  </template>

  <template v-slot:footer="">
    <button type="button" class="close btn-block" data-dismiss="modal" aria-label="Close" @click="closeModal">Finished</button>   
  </template>
</the-modal>

</template>
<script>
  import Resource from '@/api/resource';
  import { addContentToCollection } from '@/api/collection';
  import { showLoader, hideLoader } from '@/utils/helpers';
  const collectionResource = new Resource('collections');
  import checkPermission from '@/utils/permission';
  import permission from '@/directives/permission';

  export default {
    name: 'createCollectionModal',
    directives: { permission },
    components: {
      TheModal: () => import('./show.vue'),
    },
    props: {
      content: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        loader: null,
        showListNameError: false,
        showListTypeError: false,
        options : [{label:"Persönliche Merkliste",value:"privat"}],
        form: {
          name: "",
          app: null,
          description: '',
          type: 'privat',
        },
      }
    },
    mounted(){
      if(this.apps!= null && this.apps.length > 0){
        this.options.push({value: 'app', label: "Merkliste einer App"});
      }

      if(this.institution!= null){
        if(this.checkPermission({elem: this.institution ,permission: 'createCollections'})){
          this.options.push({value: 'institution', label: "Merkliste einer App"});
        }
      }
    },
    computed: {
      user(){
        return this.$store.state.user;
      },
      apps(){
        if(this.user.apps!=null){
          var appCollection = [];
          /*get all apps for this user where this user has the permission to create Collections*/
          for(var j = 0; j < this.user.apps.length; j++){
            if(this.checkPermission({elem: this.user.apps[j] ,permission: 'createCollections'})){
              appCollection.push(this.user.apps[j]);
            }
          }        
          return appCollection;
        }
        return null;
      },
      institution(){
        if(this.user.institution!=null){
          return this.user.institution;
        }
        return null;
      },
    },
    methods: {
      checkPermission,
      showLoader,
      hideLoader,
      dispatchCreateCollection() {

        if(!this.validateListTitle()){
          return;
        }

        this.loader = this.showLoader(this.loader);

        if(this.content==null){
          var data = {'content':null,'collection':this.form};
        }
        else {
          var data = {'content':this.content,'collection':this.form};
        }

        collectionResource.store(data)
        .then(() => {

          this.$notify({
            duration: 2500,
            title: "Liste angelegt!",
            text: "Die Liste wurde erfolgreich gespeichert."
          });

          /*we need to reload the user to get the permissions*/
          this.$store.dispatch('loadUser');

        })
        .finally(() => {
          this.$store.dispatch('loadUserCollections');
          this.loader = this.hideLoader(this.loader);
          this.closeModal();

        });
      },
      validateListTitle(){
        if(!this.form.name!=""){
          this.showListNameError=true;
          return false;
        }
        else if(!this.form.type!=""){
          this.showListTypeError=true;
          return false;
        }
        else{
          this.showListNameError=false;
          this.showListTypeError=false;
          return true;
        }
      },
      closeModal(){
        this.$emit("closeModal");
      },
      addContentToCollection(collection){

        this.loading = true;

        addContentToCollection(this.content,collection)
        .then(() => {
          this.content.in_collection = true;
          this.$notify({
            duration: 2500,
            title: 'Inhalt gespeichert!',
            text: 'Der Inhalt wurde erfolgreich zu der Merkliste hinzugefügt.'
          });

        })
        .catch(error => {

          console.log(error);

          this.$notify({
            type: 'error',
            duration: 2500,
            title: 'Fehler!',
            text: 'Der Inhalt konnte nicht gespeichert werden.'
          });

        })
        .finally(() => {
          this.$store.dispatch('loadUserCollections');
          this.$store.dispatch('loadUser');
          this.loading = false;
        });

      },
    },              
  };
</script>

<style lang="scss" scoped="">
  @import '@/scss/_variables.scss';
  
  .close {
    font-size: 14px;
    border: 1px solid;
    padding: 0.75rem 2.6rem;
    border-radius: 100px;
  }

  .reiseplan-titel {
    border: 1px solid #ced4da !important;
    border-radius: 0.25rem;
  }

  .form-title, .description-text {
    align-items:stretch;
    margin-top: 25px;
  }

  .form-group {
    display: block;
  }

  .validation-error {
    color: red;
    font-size: 14px;
  }

  .collection-type-select {
    border: 1px solid #ced4da !important;
    width: 100% !important;
  }

</style>
